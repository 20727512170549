import ApplicationController from './application_controller'
import Fetchers from 'core/fetchers';

export default class PresetFormController extends ApplicationController {
  static targets = ['productGroupContainer', 'productGroupButton', 'previewContainer',
    'markupInput', 'presetRadio', 'form', 'toggleRow', 'previewToggleButton']

  static values = {
    initialProductGroupId: String,
    previewPath: String,
  }

  initialize() {
    this.visibleTab = null;
    this.selectedPresetId = null;
  }

  connect() {
    this.initVisibleTab();
    this.updateSelectedPreset()
    this.updateTableVisibility();
    this.disableIrrelevantInputs();
    this.loadPreview()
  }

  changeTab(event) {
    this.setVisibleTab(event.params.id)
    this.updateSelectedPreset()
    this.updateTableVisibility();
    this.disableIrrelevantInputs();
    this.loadPreview()
  }

  updateForm() {
    this.updateSelectedPreset()
    this.disableIrrelevantInputs()
    this.loadPreview()
  }


  selectedPreset() {
    let id;
    this.presetRadioTargets.forEach((radio) => {
      if (radio.checked === true) {
        id = radio.value;
      }
    });
    return id;
  }

  setVisibleTab(id) {
    if (id.length === 0) { return; }

    this.visibleTab = String(id);
  }


  updateTableVisibility() {
    this.productGroupContainerTargets.forEach((container) => {
      if (container.dataset.id === this.visibleTab) {
        container.classList.remove('hide')
      } else {
        container.classList.add('hide')
      }
    });
  }

  disableIrrelevantInputs() {
    this.markupInputTargets.forEach((input) => {
      if (input.dataset.productGroupTypeId === this.visibleTab && this.selectedPresetId === '') {
        input.disabled = false;
      } else {
        input.disabled = true;
      }
    });
  }

  updateSelectedPreset() {
    this.selectedPresetId = this.selectedPreset()
  }

  previewToggle() {
    const show = this.previewToggleButtonTarget.checked
    this.toggleRowTargets.forEach((target) => {
      if (show) {
        target.classList.remove('hide')
      } else {
        target.classList.add('hide')
      }
    });
  }

  loadPreview() {
    let formData = new FormData(this.formTarget);
    Fetchers.fetchGeneric(
      this.previewPathValue, 'POST', formData,
    )
      .then((response) => response.json())
      .then((data) => {
        this.previewContainerTarget.innerHTML = data.html
        this.previewToggle()
      })
  }

  serializedFormData() {
    let formData = new FormData(this.formTarget);

    const data = Array
      .from(formData.entries())
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    return data;
  }

  initVisibleTab() {
    if (this.hasInitialProductGroupIdValue) {
      this.setVisibleTab(this.initialProductGroupIdValue);
    }
  }
}
