export const calculatePropertiesSize = function(properties) {
  const jsonString = JSON.stringify(properties);
  // Assumes UTF-8 encoding
  const sizeInBytes = new TextEncoder().encode(jsonString).length;
  console.log("Size of `properties` in bytes:", sizeInBytes);
  if (sizeInBytes > 32000) {
    console.warn("Warning: JSON size exceeds Rudderstack's 32KB limit");
  }
  return sizeInBytes;
}
