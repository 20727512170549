import ApplicationController from '../application_controller';

const checkoutStepSubmittedEvent = 'Checkout Step Submitted';

const buttonToStep = {
    'Next: Contact & Payment Info': 'shipping',
    'Use This Address': 'verify_address',
    'Use Recommended Address': 'verify_address',
    'Place Order': 'payment',
    'PayPal': 'payment',
    'GPay': 'payment',
    'Apple Pay': 'payment'
};

export default class CheckoutStepSubmittedController extends ApplicationController {
  static values = {
    buttonLabel: String,
    cartId: String,
    errors: Array
  }

  connect() {
    this.element[this.identifier] = this;
  }

  initialize() {
    this.step = buttonToStep[this.buttonLabelValue];
  }

  setErrors() {
    const errorMessageNodes = document
      .querySelectorAll('.tp-input-field--text-error .tp-input-field--error-message');

    const errors = [];
    for (let errorMessageNode of errorMessageNodes) {
      const error = errorMessageNode.innerText;
      error && errors.push(error);
    }

    this.errorsValue=(errors);
  }

  submissionSuccessful() {
    return !this.errorsValue.length;
  }

  cartId() {
    let cartId;

    try {
      cartId = JSON.parse(this.cartIdValue).public_id;
    } catch {
      cartId = this.cartIdValue;
    }

    return cartId;
  }

  properties() {
    return { 
      button_label: this.buttonLabelValue,
      cart_id: this.cartId(),
      email_opt_in: document.querySelector('#subscribe_email_subscribe').checked,
      errors: this.errorsValue,
      step: this.step,
      text_opt_in: document.querySelector('#subscribe_attentive_subscribe').checked,
      submission_successful: this.submissionSuccessful()
    };
  }

  track() {
    try {
      if (window.rudderanalytics) {
        this.setErrors();
        const properties = this.properties();
        window.rudderanalytics.track(checkoutStepSubmittedEvent, properties);
      }
    } catch (error) {
      console.error('RS Checkout Error', error);
    }
  }
}
