const usZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export default class {
  shipZipMessage(value, { shipCountryName, zipLabel }) {
    return this.postalCodeMessage(value, shipCountryName, zipLabel);
  }

  shipStateMessage(value, { type, requiresState, stateLabel }) {
    if (value || requiresState === 'false') return '';

    if (type === 'text' && requiresState === 'true') return this.presenceMessage(value, { name: stateLabel });

    return `Select a ${stateLabel}.`;
  }

  restrictedFormEntryMessage(value, name) {
    const message = `${name} may not contain`;

    if (this.containsEmojis(value)) return `${message} emojis.`;
    if (this.containsJapanese(value)) return `${message} Japanese characters.`;
    if (this.containsHebrew(value)) return `${message} Hebrew characters.`;
    if (this.shouldNotContainEmail(value, name)) return `${name} may not be an email address.`;
  }

  shouldNotContainEmail(value, name) {
    return name !== 'Billing Email' && this.isValidEmailAddress(value);
  }

  containsEmojis(string) {
    const regex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
    return regex.test(string);
  }

  containsJapanese(string) {
    const regex = /\p{Script=Han}|\p{Script=Katakana}|\p{Script=Hiragana}/u
    return regex.test(string);
  }

  containsHebrew(string) {
    const regex = /\p{Script=Hebrew}/u
    return regex.test(string);
  }

  presenceMessage(value, { name }) {
    return (value.length > 0) ? '' : `${name} is required.`;
  }

  billPresenceMessage(value, { name, billingSameAsShipping, paymentOption }) {
    if (paymentOption === 'CreditCard' && !billingSameAsShipping) {
      return this.presenceMessage(value, { name });
    }

    return '';
  }

  billZipMessage(value, { name, billingSameAsShipping, paymentOption, billCountryName }) {
    if (billingSameAsShipping) return '';

    if (!value) return this.presenceMessage(value, { name });

    if (
      paymentOption === 'CreditCard'
      && billCountryName === 'United States'
      && !this.isValidUSZipCode(value)
    ) {
      return 'A valid US ZIP Code is required.';
    }

    if (value) return this.postalCodeMessage(value, billCountryName, name);

    return '';
  }

  postalCodeMessage(value, countryName, zipLabel) {
    if (!value) return this.presenceMessage(value, { name: zipLabel });

    let regex;
    let message;

    if (countryName == 'United States') {
      regex = usZipCodeRegex;
      message = 'A valid US ZIP Code is required.';
    } else if (countryName == 'Canada') {
      regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      message = 'A valid Canadian Postal Code is required.';
    } else if (countryName == 'United Kingdom') {
      regex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
      message = 'A valid UK Postal Code is required.';
    }

    if (!regex || regex.test(value)) return '';

    return message;
  }

  phoneMessage(value, { name, shipCountryName }) {
    //phone number not required when shipping domestically and number not entered
    if (shipCountryName === 'United States' && !value) return '';

    const numbers = value.replace(/\D/g, '');

    // Both checks required to accurately determine an international order without a phone number entered
    if (!numbers && !value) return this.presenceMessage(numbers, { name });

    if (shipCountryName === 'United States') {
      if (numbers.length < 10) {
        return 'Provide a valid phone number.';
      }
    } else if (numbers.length < 8) {
      return 'A valid phone number is required for international shipments.';
    }

    return '';
  }

  emailMessage(value, { name }) {
    if (!value) return this.presenceMessage(value, { name });

    if (this.isValidEmailAddress(value)) return '';

    return 'A valid email address is required.'
  }

  isValidEmailAddress(text) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(text);
  }

  isValidUSZipCode(text) {
    return usZipCodeRegex.test(text);
  }
}
