import InputChangeMonitor from 'core/components/utilities/input_change_monitor'

class StoreSlugFieldManager {
  constructor(
    fieldSelector,
    warningSelector,
  ) {
    this.monitor = new InputChangeMonitor(fieldSelector, warningSelector);
    this.monitor.init();

    this.field = document.querySelector(`.${fieldSelector}`);
    this.form = this.field.form;

    this.dialogWrapper = document.querySelector('.jsDialogComponentWrapper');
    this.dialogSwitch = document.querySelector('.jsDialogSwitch');
  }

  init() {
    this.dialogSwitch.addEventListener('click', e => this.handleFormSubmit(e));
  }

  handleFormSubmit(e) {
    if (this.monitor.showWarningMessage()) {
      e.preventDefault();
      this.dialogWrapper.dialogComponent.triggerDialog(e);
    } else {
      this.form.submit();
    }
  }
}

export default StoreSlugFieldManager;
