import ApplicationController from '../application_controller';

const checkoutStepViewedEvent = 'Checkout Step Viewed';
const stepsTrackedOnLoad = ['shipping'];

export default class CheckoutStepViewedController extends ApplicationController {
  static values = { cartId: String, step: String };

  connect() {
    if (stepsTrackedOnLoad.includes(this.stepValue)) this.track();
  }

  properties() {
    return { cart_id: this.cartId(), step: this.stepValue };
  }

  cartId() {
    let cartId;

    try {
      cartId = JSON.parse(this.cartIdValue).public_id;
    } catch {
      cartId = this.cartIdValue;
    }

    return cartId;
  }

  track() {
    try {
      if (window.rudderanalytics) {
        const properties = this.properties();
        window.rudderanalytics.track(checkoutStepViewedEvent, properties);
      }
    } catch (error) {
      console.error('RS Checkout Error', error);
    }
  }
}
