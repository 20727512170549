/* eslint-disable import/no-extraneous-dependencies */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/* Libraries */

/* Core */
import 'core/controllers'
import { TeePublic } from 'core/globals'
import initRudderstackCheckoutTracker from 'core/services/rudderstack_checkout_tracker';
import RudderstackHelpers from 'core/services/rudderstack_helpers';
import initProductClicks from 'core/services/rudderstack'

/* Shared */
import 'shared/autocomplete'
import Stores from 'shared/stores'
import Validators from 'shared/validators'
import FormsNew from 'shared/legacy_js/forms_new'
import initUpdateSaleTimes from 'shared/legacy_js/home'
import EmailHelpers from 'shared/helpers/email_helpers'
import mtrlModals from 'shared/modals/mtrl_modals'
import ProductHelper from 'shared/helpers/ProductHelper'
import initExploreNav from 'shared/navigation/explore_nav'

/* Packs Contents */
import ArtistTools from 'packs_contents/application/artist'
import LandingSlider from 'packs_contents/application/landing/slider';
import ProductPage from 'packs_contents/product_page' // This import should be cleaned up. Application shouldn't import ProductPage

TeePublic.ArtistTools = ArtistTools;
TeePublic.EmailHelpers = EmailHelpers;
TeePublic.FormsNew = FormsNew;
TeePublic.LandingSlider = LandingSlider;
TeePublic.ProductPage = ProductPage;
TeePublic.ProductHelper = ProductHelper;
TeePublic.Stores = Stores;
TeePublic.Validators = Validators;
TeePublic.initRudderstackCheckoutTracker = initRudderstackCheckoutTracker;
TeePublic.RudderstackHelpers = RudderstackHelpers;
TeePublic.initProductClicks = initProductClicks;

mtrlModals.mtrlModal();
mtrlModals.mtrlCollapsible();

initExploreNav();
initUpdateSaleTimes();
