import Posters from './posters';
import Renderers from './renderers';
import Updaters from './updaters';
import Utilities from './utilities';

const Binders = {};

/*
 * --------------------------------------------------
 *  Begin private methods
*/

function bindAddToCartEvents(storeId, designId) {
  document.querySelector('.jsAddToCart').addEventListener('click', (event) => {
    event.preventDefault();
    const { ProductOptions } = TeePublic;
    const { hierarchy } = TeePublic.ProductOptions.CanvasOptions;
    const selections = Utilities.collectSelections('form.jsConfigOptions', ProductOptions, false);
    const valid = Utilities.validateAddToCart(selections, hierarchy, false);
    if (!valid) return;

    const selectionsFullText = Utilities.collectFullTextSelections('form.jsConfigOptions', hierarchy, true);
    const sanitizedProducts = Utilities.sanitizeProducts(ProductOptions);
    const product = Utilities.findProductFromSelections(sanitizedProducts, selections, ProductOptions);
    const quantity = document.querySelector('#jsProductPageQuantity')?.value || 1;

    const postOptions = {
      product_id: product.id,
      design_id: designId,
      quantity,
      store_id: storeId,
      campaign_id: TeePublic.campaign.id,
    };
    if (window.dataLayer) {
      TeePublic.ProductVendorHelper
        .updateDataLayerAddToCart(postOptions, selections, selectionsFullText, product);
    }
    Posters.addToCart(postOptions);
  });
}

function bindColorQuickSelectEvents() {
  document.querySelector('html').addEventListener('click', (event) => {
    if (!event.target.classList.contains('jsColorQuickSelect')) return;

    const colorId = event.currentTarget.dataset.color_id;
    const element = document.querySelector(`#color_new_${colorId}`);
    element.checked = true;
    element.dispatchEvent(new Event('change'));

    TeePublic.HorizontalScroll.toInput(element, 4);
  });
}

function bindOOSEvents() {
  document.querySelector('html').addEventListener('click', (event) => {
    if (!event.target.classList.contains('jsOutOfStock')) return;

    console.log('Todo: bindOOSEvents');
  });
}

function bindOptionChangeEvents(gallery) {
  document.querySelector('form.jsConfigOptions').addEventListener('change', (event) => {
    const { target } = event;
    if (!target.matches('input, select')) return;

    Renderers.renderOptionsOnChange(event);
    // Move to the next event loop to speed up INP web vitals
    window.setTimeout(() => Updaters.updatePdpProductData(gallery), 0);
  });
}

function bindExchangeOptionsChangeEvents(oliId) {
  const productOptions = TeePublic.Exchange.Data[oliId];
  const sanitizedProducts = Utilities.sanitizeProducts(productOptions);

  document.querySelector(`.jsExchangeOli${oliId}Options`).addEventListener('change', (event) => {
    if (!event.target.matches('select')) return;

    Renderers.renderExchangeOptionsOnChange(event, oliId);
    const selections = Utilities.collectSelections(`.jsExchangeOli${oliId}Options`, productOptions, true);
    const product = Utilities.findProductFromSelections(sanitizedProducts, selections, productOptions);
    const inputElement = document.querySelector(`.jsExchangeOli${oliId}ProductId`);

    inputElement.value = product.id;
    inputElement.dispatchEvent(new Event('change'));
  });
}

/*
  * --------------------------------------------------
  *  Begin public methods
  */

Binders.bindExchangeEvents = (oliId) => {
  bindExchangeOptionsChangeEvents(oliId);
}

Binders.bindProductPageEvents = (defaultImgIndex, storeId, designId, gallery) => {
  bindAddToCartEvents(storeId, designId);
  bindOptionChangeEvents(gallery);
  bindOOSEvents();
  bindColorQuickSelectEvents();
}

export default Binders;
